<template>
  <div class="container">
    <div class="content">
      <div class="section inner-H">
        <div class="error">
          <p class="error-headline">404<span class="error-headline-sub">ご指定のページが見つかりません</span></p>
          <p class="error-title">ご指定のページが見つかりません</p>
          <p class="error-text">アクセスしようとしたページは、削除・変更されたか、現在利用できない可能性があります。</p>
          <router-link class="btn btn-main" :to="{ name: 'EKYCEntry' }">トップへ戻る</router-link>
        </div>
      </div>
    </div>
    <Footer :menu="false" />
  </div>
</template>

<script>
export default {
  name: 'Error404',
  data: function() {
    return {
      //headerData
      pageName: '404',
      display: false,
    };
  },
};
</script>
